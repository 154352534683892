import { IVDVersion, IVehicle, VehicleControlTypeEnum, VehicleStagesEnum } from '../vehicle/vehicle';
import { IInspection } from '../inspection/inspection';
import { IVehicleLead } from '../vehicle-lead/vehicleLead';
import { IUser } from '../../../contexts/user/UserContext';
import { IListing } from '../marketPlace/marketplace';
import { INegotiation } from '../negotiation/negotiation';
import { ISellerPayment } from '../seller-payment/seller-payment';

export interface ILien {
    createdAt: string;
    estimatedBalance: number;
    financialInstitutionId: number;
    financialInstituteName: string;
    documentPath: string;
    id: number;
    modifiedAt: string;
}

export interface IFinancialInstitution {
    createdAt: string;
    dealerId: number;
    id: number;
    modifiedAt: string;
    name: string;
}

export enum ISaleType {
    dealer = 'dealer',
    buyer = 'buyer'
}

export interface IDealer {
    address: string;
    fullAddress: string;
    address1?: string;
    address2?: string | null;
    email?: string;
    city?: string;
    country?: string;
    averageStars: number;
    distance: number;
    id: number;
    lat: number;
    long: number;
    name: string;
    phoneNumber: string;
    isFullyOnboarded: boolean;
    isPaid: boolean;
    isDeactivated: boolean;
    postalCode?: string;
    provinceId?: number;
    province?: { [key: string]: any };
    reviewsCount: number;
    timezone: string;
    workingDays: string;
    workingEndHour: string;
    workingStartHour: string;
    description?: string | null;
    operatingHours?: { [key: string]: any }[]
}

export interface IDealerIntake {
    accidentDesc?: string;
    currentStep?: string;
    dealerId?: number;
    dealerVehicleId?: number;
    doorsCount?: number;
    externalDamageDesc?: string;
    hasAccident?: boolean;
    hasExternalDamage?: boolean;
    hasInternalDamage?: boolean;
    hasLien?: boolean;
    liens: ILien[];
    hasMechanicalIssues?: boolean;
    hasWarningLights?: boolean;
    hasWarranty?: boolean;
    id?: number;
    internalDamageDesc?: string;
    isModified?: boolean;
    isSmokedIn?: boolean;
    isUndrivable?: boolean;
    isWrittenOff?: boolean;
    mechanicalIssuesDesc?: string;
    modificationDesc?: string;
    odometer?: number;
    plateNumber?: string;
    priceListing?: number;
    proofOfOwnershipImgPath?: string;
    setOfKeys?: number;
    status?: string;
    vdBodyTypeId?: number;
    vdColorId?: number;
    vdVersion?: IVDVersion
    vdVersionId?: number;
    vehicleLeadId?: number;
    vehiclesIssuesDesc?: null
    vin?: string;
    warningLightsDesc?: string;
    warrantyDesc?: string;
    wasEmergencyVehicle?: boolean;
    wasLease?: string;
    wasNotPrivateCar?: boolean;
    wasPoliceVehicle?: string;
    wasRental?: string;
    wasTaxi?: string;
}

export interface IOwnership {
    address: string;
    apt: string;
    city: string;
    createdAt: string;
    driverLicenseBackPath: string;
    driverLicenseExpireDate: string;
    driverLicenseFrontPath: string;
    driverLicenseNumber: string;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    modifiedAt: string;
    phone: string;
    postalCode: string;
    provinceId: number
    userId: number
}

export interface IDealerVehicle {
    id: number;
    vin: string;
    stockNumber: number;
    stage: VehicleStagesEnum;
    controlType: VehicleControlTypeEnum;
    vdVersionId: number;
    vdVersion: IVDVersion;
    vehicleLeadId: number;
    vehicleLead: IVehicleLead;
    vehicleName?: string;
    dealerIntake: IDealerIntake;
    dealerId: number;
    stockImageUrl: string;
    photoPath: string;
    setOfKeys: number;
    doorsCount: number;
    vdBodyTypeId: string;
    vdExteriorColorId: string;
    isFlagged: boolean;
    createdAt: string;
    modifiedAt: string;
    expiringAt: string;
    soldAt: string;
    originalListedAt: string;
    soldPrice: number;
    soldToDealer: boolean;
    isDealerListed: boolean;
    canRenew: boolean;
    stageBeforeArchive: VehicleStagesEnum;
    lastListingPrice: string;
    inspection: IInspection;
    listing: IListing;
    negotiations: INegotiation[];
    ownerships?: IOwnership[];
    saleType?: ISaleType;
    consignment: {
        lastInputState: {
            fees: {
                'sellerFee': string;
                'sellerRenewalFee': string;
                'sellerTerminationFee': string;
            },
            dealer: IDealer,
            seller: IUser,
            vehicle: IVehicle,
            jointOwners: [
                {
                    'lastName': string;
                    'firstName': string;
                }
            ],
            hasJointOwners: boolean;
            extensionPeriod: string;
            reverseDealerData: {
                name: string;
                phone: string;
                address: string;
            },
            terminationPeriod: string;
            extensionPeriodTextual: string;
            terminationPeriodTextual: string;
        }
    }
    sellerPayment?: ISellerPayment;
    saleDealerFee: number;
    saleDealerFeePercent: number;
    saleDealerFeeMinimum: number;
    saleDealerFeeType: VehicleSaleDealerFeeTypeEnum;
}

export interface IDealerVehicleSummary {
    acceptedVehiclesCount: number;
    activeVehiclesCount: number;
    expiredsCount: number;
    expiringsCount: number;
    incomingVehiclesCount: number;
    listedVehiclesCount: number;
    negotiatingVehiclesCount: number;
    qualifyingVehiclesCount: number;
    soldVehiclesCount: number;
    stalesCount: number;
}

export interface IDealerClient {
    clientType: 'seller' | 'buyer' | 'buyerSeller',
    dealerVehicles: {
        id: number;
        isSeller: boolean;
        vehicleName: string;
        stockNumber: string;
        photoPath: string;
        stage: string;
    }[],
    user: IUser,
}

export enum VehicleSaleDealerFeeTypeEnum {
    DYNAMIC = 'dynamic',
    TIERED = 'tiered'
}